import React from 'react'
import { FixedSizeList as List } from 'react-window'
import useWindowDimensions from '../custom-hooks/useWindowDimensions'

import StaggeredCard from '../components/Homemade/HomePage/component/StaggeredCard'

let shop = {
	"_id": "6107b282039215001740c196",
	"schedule": [
		{
			"open": "12:00 pm",
			"closed": "10:00 pm"
		},
		{
			"open": "12:00 pm",
			"closed": "10:00 pm"
		},
		{
			"open": "12:00 pm",
			"closed": "10:00 pm"
		},
		{
			"open": "12:00 pm",
			"closed": "10:00 pm"
		},
		{
			"open": "12:00 pm",
			"closed": "10:00 pm"
		},
		{
			"open": "12:00 pm",
			"closed": "10:00 pm"
		},
		{
			"open": "12:00 pm",
			"closed": "10:00 pm"
		}
	],
	"logo": "https://com-sparkle-s3-prod.imgix.net/shop/logo/6107b282039215001740c196.png",
	"banner": "https://com-sparkle-s3-prod.imgix.net/shop/banner/6107b282039215001740c196.png",
	"preparation": "At least one day",
	"status": true,
	"long": "120.986145",
	"lat": "14.607551",
	"pickUpNotes": "",
	"location": {
		"type": "Point",
		"coordinates": [
			120.986145,
			14.607551
		]
	},
	"name": "Poldo’s Steakhouse",
	"user": "6107b282039215001740c195",
	"address": "Poldo's Steakhouse, Barlin Street, Sampaloc, Manila, Metro Manila, Philippines",
	"createdAt": "2021-08-02T08:53:22.604Z",
	"updatedAt": "2022-03-22T07:14:10.369Z",
	"__v": 0,
	"inspiration": "",
	"id": 1,
	"isActive": true,
	"vouchers": [],
	"isAvailable": true,
	"order": 5,
	"shopId": "6107b282039215001740c196",
	"shopDistance": "0.67 km",
	"shopDistanceDouble": 0.6708000000000001,
	"deliveryFee": 45
}


let shops = []


for (let a = 0; a < 200; a++) {
	shops[a] = shop
}

const Row = ({ index, style }) => {
	let shop = shops[index]
	return <div style={style}>
		<StaggeredCard shop={shop} Size={"Small"} />
		<StaggeredCard shop={shop} Size={"Large"} />
	</div>
}



const Test = () => {
	const { width, height } = useWindowDimensions()

	return <div style={{
		display: 'flex',
		flexDirection: 'row'
	}}>  <List height={height} itemCount={shops.length} itemSize={576} width={width/2}>
			{Row}
		</List>
		<List height={height} itemCount={shops.length} itemSize={576} width={width/2}>
			{Row}
		</List>
	</div>
}

export default Test